<template>
  
  <div class="m-page">

   <section class="m-grid-search">
        <span> 分组名称: </span>
       <znl-input
        border
        width="150px"
        placeholder="分组名称"
        clearable
        class="m-margin--right-10"
        v-model="GroupName"
      >
      </znl-input>
    </section>
    <section
      class="m-scorebox"
      v-for="(item, index) in scoreCofig"
      :key="index"
    >
    
      <span> {{ item.DisplayName }} </span>
      <div class="c-scoreitem c-checkbox">
        <el-checkbox
          v-for="(todo, index) in item.ScoremItemGetLines"
          :key="index"
          :disabled="todo.IsSystem"
          v-model="todo.Ischecked"
          >{{ todo.ItemName }}</el-checkbox
        >
      </div>
    </section>



    <el-row class="m-rowbutton">
      <el-button type="primary" size="medium"  @click="onSeve">确定</el-button>
    </el-row>
  </div>
</template>

<script>
import { GetScoreGroupItemList, scoreGroupConfigEdit } from "@/api/config";
export default {
  name: 'requiredFieldsGroupEditView',
  data() {
    return {
      scoreCofig: [],
      GroupName:""
    };
  },
  methods: {
    async onInit() {
      let score = await GetScoreGroupItemList();
      if (score) {
   
        this.scoreCofig = score.scoreCofig;
      }
    },
    async onSeve() {
      let sendData={
        scoreCofig:this.scoreCofig,
        GroupName:this.GroupName
      }
      let GroupType=""
      this.scoreCofig.forEach((item) => {
        item.ScoremItemGetLines.forEach(element => {
         if(element.Ischecked===true){
           if(GroupType=="")
             {
                GroupType=item.DisplayName;
             }
            if(GroupType!=item.DisplayName)
            {
              this.$message.warning('不可跨类型分组');
                 throw new Error('End')

            }
         }
        });
      });
      console.log(sendData)
      
      let reslut = await scoreGroupConfigEdit(sendData);
      if(reslut){
       this.$message.success("保存成功")
        this.$router.push({
        name: 'requiredFieldsGroup'
      })
      await this.$store.dispatch('tagsView/delView', this.$route)
      }
      this.onInit()
    },
  },
  created() {
    this.onInit();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.m-page {
  font-size: 14px;
  margin-left: 30px;
  margin-top: 20px;

  .el-checkbox {
    margin-right: 20px;
  }
  .el-checkbox__label {
    font-size: 12px;
  }
}
.m-page span {
  font-weight: 600;
}
.m-scorebox {
  line-height: 26px;
  margin-bottom: 15px;
}

.c-scoreitem {
  border: 1px #dddddd solid;
  width: 80%;
  padding: 10px;
}
.m-rowbutton {
  width: 80%;
  text-align: right;
}
</style>
